@if(title){
<div class="details-title">
    <h4>{{ title }}</h4>
</div>
}
<it-list class="custom-list">
    <div class="custom-list-title">
        <h5>{{ listTitle }}</h5>
    </div>
    @for (item of items; track item.uid) {
    <it-list-item class="list-data" [active]="item.checked && !readonly">
        @if(data && !readonly){
        <it-checkbox [(ngModel)]="item.checked" (ngModelChange)="updateData()" [ariaDisabled]="disabled"></it-checkbox>
        }
        <div class="flex-grow-1">
            <div class="list-title">
                <div class="text">{{ item.abilitazione | qualificationName | async }}</div>
            </div>
            <div class="list-info">
                <span>{{ item.abilitazione | qualificationDesc | async }}</span>
            </div>
        </div>
    </it-list-item>
    } @empty {
    <div class="text" [class]="title || listTitle ? 'py-3' : 'p-3'">{{ "it.portal.no-results" | translate }}</div>
    }
</it-list>
