import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import {
    ItCheckboxComponent,
    ItListComponent,
    ItListItemComponent,
    ItNotificationService,
    ItTooltipDirective,
} from "design-angular-kit";
import { RoleService } from "@app/services/role.service";
import { RouterOutlet } from "@angular/router";
import { TooltipPlacement } from "@app/app.constants";
import { Qualification } from "@app/models/role";
import { FormGroup, FormsModule, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { DecoStoreService } from "@app/stores/deco.store";
import { QualificationDescPipe, QualificationNamePipe } from "@app/pipes/qualification.pipe";
import { Subscription } from "rxjs";
import { AsyncPipe } from "@angular/common";

@Component({
    selector: "app-qualification-list",
    standalone: true,
    templateUrl: "./quolification-list.component.html",
    imports: [
        RouterOutlet,
        TranslateModule,
        ItListComponent,
        ItListItemComponent,
        ItTooltipDirective,
        ItCheckboxComponent,
        FormsModule,
        QualificationDescPipe,
        QualificationNamePipe,
        AsyncPipe,
    ],
})
export class QualificationListComponent implements OnInit {
    @Input() title: string | null = null;
    @Input() listTitle: string | null = null;
    @Input() options: Qualification[] | null = null;
    @Input() data: Qualification[] | null = null;
    @Input() disabled: boolean = false;
    @Input() readonly: boolean = false;
    @Output() dataChange = new EventEmitter<Qualification[] | null>();
    readonly TooltipPlacement = TooltipPlacement;
    items: Qualification[] | null = null;

    private subscriptions: Subscription[] = [];

    constructor(private roleService: RoleService, private decoStoreService: DecoStoreService) {}

    get resultsTranslationKey() {
        return `it.portal.results.${this.items?.length !== 1 ? "other" : "one"}`;
    }

    ngOnInit() {
        this.getQualifications();
    }

    getQualifications() {
        const roleServiceCall = this.data
            ? this.decoStoreService.getQualifications()
            : this.roleService.getAllQualifications();

        this.subscriptions.push(
            roleServiceCall.subscribe({
                next: (res) => {
                    this.decoStoreService.setQualifications(res);
                    this.items = res.risultati.map((item) => ({ ...item, abilitazione: item.uid }));
                    this.data &&
                        this.items.forEach((x) => {
                            x.checked = !!this.data?.find((y: Qualification) => y.abilitazione === x.abilitazione);
                        });
                    if (this.readonly) this.items = this.items.filter((x) => x.checked);
                },
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propertyName in changes) {
            switch (propertyName) {
                case "options":
                    if (this.options) {
                        this.items = this.options;
                        this.data &&
                            this.items.forEach((x) => {
                                x.checked = !!this.data?.find((y: Qualification) => y.abilitazione === x.abilitazione);
                            });
                    }
                    break;
            }
        }
    }

    updateData() {
        if (this.items)
            this.data = this.items
                .filter((x) => x.checked)
                .map((x: Qualification) => ({ uid: x.uid, abilitazione: x.abilitazione } as Qualification));
        this.dataChange.emit(this.data);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}
